@import "../../css/buttons.css";
@import "../../App.css";

.section-listings {
  background: #f5f5f5;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  position: relative;
}

.referral_lead {
  color: #3179ff;
  font-weight: 400;
  margin: 0 0 2rem;
}
.referral_benefits {
  padding: 20px;
}

.generate-link-button {
  margin-bottom: 20px;
  padding: 20px;
  width: 25%;
  background-color: #3179ff;
  color: white;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
}

.listing {
  border: 1px solid #b2b2b2;
  background: #fff;
  padding: 1rem 0;
  margin-bottom: 1.4rem;
}

.listing:last-child {
  margin-bottom: 0;
}

.position_name {
  margin: 0.8rem 1rem 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.4;
}

.referral_description {
  margin: 0 0 2rem;
  line-height: 1.4;
  font-size: 1rem;
}

.referral_info {
  margin: 0 1rem 1.5rem;
  line-height: 1.4;
  font-size: 1rem;
  white-space: pre-wrap;
  line-height: 1.8;
}
.referral_conditions {
  margin: 0 1rem 1.5rem;
  line-height: 1.4;
  font-size: 0.6rem;
  text-align: left;
}
.position-link {
  margin: 0 1rem;
  text-align: center;
}

.whatsapp {
  background-color: #58be55;
  border: #58be55;
}
.channel-icon {
  margin-bottom: 5px;
  margin-left: 5px;
}

.apply-button {
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
  background-color: #3179ff;
  color: white;
  cursor: pointer;
}

.position_link-help {
  border-top: 1px solid #b2b2b2;
  padding: 1rem 1rem 0;
  margin: 1.5rem 0 0;
  color: #8f8f8f;
  font-size: 0.875rem;
  line-height: 1.4;
}

.position_link-help span {
  color: #3179ff;
  margin-right: 8px;
}

.referral_conditions_container {
  padding-top: 3%;
}

.landing-redirect-second-title {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  align-items: center;
  text-align: center;
  color: #003e55;
}

.landing-redirect-title {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 32px;
  align-items: center;
  text-align: center;
  color: #004054;
}

.landing-redirect-description {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  margin: 0% 2% 2% 2%;
  color: #000000;
}

.landing-redirect-logo {
  border-radius: inherit;
  width: 98%;
  margin: auto;
  height: auto;
  background: #f4f4f4;
}

.landing-redirect-main-card {
  box-sizing: border-box;
  width: 178px;
  height: 80px;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1%;
  border-radius: 20px;
}

.landing-redirect-main-card:hover {
  box-shadow: 0px 4px 4px rgb(0 0 0 / 10%), 0px 1px 6px rgb(0 0 0 / 10%);
}

.landing-redirect-secondary-card-link {
  border: none;
  background: none;
}

.landing-redirect-secondary-card-link:hover {
  text-decoration: none;
  border: none;
  background: none;
}

.landing-redirect-secondary-card {
  box-sizing: border-box;
  width: 178px;
  height: 80px;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1%;
  border-radius: 20px;
}

.landing-redirect-secondary-card:hover {
  box-shadow: 0px 4px 4px rgb(0 0 0 / 10%), 0px 1px 6px rgb(0 0 0 / 10%);
}

.landing-redirect-card-title {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 0%;
  color: #000000;
}

.landing-redirect-card-text {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 13px;
  margin-bottom: 0%;
  color: #000000;
  text-align: left;
}

.landing-redirect-secondary-redirects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  margin: auto;
}

.landing-redirect-main-card-container {
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.landing-redirect-secondary-card-container {
  margin: 1%;
}

.landing-redirect-logo-container {
  border: solid;
  border-color: #d9d9d9;
  border-radius: 50%;
  margin-left: 7%;
  align-self: center;
  min-height: 39.6px;
  min-width: 39.6px;
  max-height: 39.6px;
  max-width: 39.6px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.landing-redirect-card-title-container {
  margin-left: 7%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
}

.landing-redirect-secondary-title-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1%;
}

.landing-redirect-division-line {
  display: flex;
  flex-direction: row;
  width: 60%;
  margin: auto;
}

.landing-redirect-division-line-section {
  width: 100%;
  height: 5px;
  border: none;
}

.landing-redirect-secondary-title-text-container {
  margin-left: 2%;
  margin-right: 2%;
  align-self: center;
}

.landing-redirect-secondary-title-text {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 0%;
  color: #000000;
}

.landing-redirect-main-card-button {
  border: none;
  background: none;
}

@media (min-width: 768px) {
  .referral-text {
    margin-top: 20px;
    margin-bottom: 20px;
    /* max-width: 50%; */
  }
  .section-listings {
    padding-bottom: 5rem;
  }
  .listing {
    margin-bottom: 1.6rem;
  }
  .referral-info {
    padding: 2rem;
  }
  .position_name {
    margin: -5px 2rem 0 0;
    min-width: 22.5%;
  }
  .referral_description,
  .referral_info {
    margin: 0 2rem 1.5rem 0;
  }
  .referral_description,
  .referral_conditions {
    margin: 0 2rem 1.5rem 0;
  }
}

@media (min-width: 992px) {
  .referral_lead {
    margin: 0 auto 2rem;
    max-width: 69vw;
  }
  .listing {
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.875rem;
  }
  .referral-info {
    padding: 2rem 2.43rem 0;
  }
}

@media (min-width: 1200px) {
  .referral_description,
  .referral_info {
    margin: 0 auto 2rem;
    max-width: 69vw;
  }

  .referral_description,
  .referral_conditions {
    margin: 0 auto 2rem;
    max-width: 69vw;
  }
}
