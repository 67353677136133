.single-position-main-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  padding: 2% 10%;
}

.single-position-name {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 63px;
  display: flex;
  align-items: center;
  color: #000000;
  width: 50%;
}

.single-position-application-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
}

.single-position-application-title {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #132e82;
  margin: 0% 0% 2% 0%;
}

.single-position-application-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.single-position-apply-button {
  border-radius: 10px;
  /* width: 226px; */
  height: 54px;
  border: none;
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
  margin: 1%;

  /* wm/white */

  color: #ffffff;
}

.single-position-details-section {
  padding: 3% 10% 3% 10%;
}

.single-position-basic-information {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 3%;
}

.single-position-basic-information-element {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 3%;
}

.single-position-basic-information-element-title {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #132e82;
}

.single-position-basic-information-element-position {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;

  color: #000000;
}

.single-position-location {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 3%;
}

.single-position-location-title {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #132e82;
}

.single-position-location-position {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;

  color: #000000;
}

.single-position-description {
  margin-bottom: 3%;
}

.single-position-description-title {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #132e82;
  margin-bottom: 1%;
}

.single-position-description-position {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;

  color: #000000;
}

.single-position-about-company {
  margin-bottom: 3%;
}

.single-position-about-company-title {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #132e82;
  margin-bottom: 1%;
}

.single-position-about-company-position {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* display: flex;
  align-items: center; */

  color: #000000;
}

.single-position-benefits-title {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #132e82;
  margin-bottom: 1%;
}

.single-position-benefits-subtitle {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;
  margin-bottom: 1%;
}

.single-position-benefits-position {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  align-items: center;

  color: #000000;
}

.single-position-faq-section {
  /* height: 650px; */
  padding: 3% 10% 3% 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #f5f5f5;
  justify-content: space-evenly;
}

.single-position-faq {
  margin: 1% 0%;
}

.single-positon-faq-section-title {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 35px;
  /* identical to box height */
  display: flex;
  align-items: center;
  margin: 1% 0%;
  color: #132e82;
}

.single-position-faq-title {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #132e82;
  margin-bottom: 1%;
}

.single-position-faq-text {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.single-position-application-footer-container {
  display: flex;
  flex-direction: row;
  padding: 3% 10%;
  justify-content: space-between;
}

.single-position-application-footer-title-section {
  width: 50%;
  margin-right: 5%;
}

.modal-apply-single-position-application-channel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-apply-single-position-selected-channel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 6%;
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}

.modal-apply-single-position-selected-channel-text {
  margin-left: 3%;
}

@media (max-width: 700px) {
  .single-position-main-section {
    flex-direction: column;
    height: 100%;
    padding: 6% 10%;
  }
  .single-position-application-container {
    width: 100%;
  }

  .single-position-name {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    font-size: 40px;
  }

  .single-position-application-buttons {
    flex-direction: column;
    align-items: center;
  }
  .single-position-apply-button {
    margin: 1%;
  }
  .single-position-application-footer-container {
    flex-direction: column;
    align-items: center;
  }
  .single-position-application-footer-title-section {
    width: 100%;
    margin: 2%;
  }

  .single-position-application-text {
    text-align: center;
  }
  .single-positon-faq-section-title {
    margin: 3% 0%;
  }
  .single-position-faq {
    margin: 3% 0%;
  }

  .single-position-application-title {
    flex-direction: row;
    justify-content: center;
  }
}

@media (max-width: 1050px) {
  .single-position-application-buttons {
    flex-direction: column;
    align-items: center;
  }
  .single-position-application-container {
    width: 50%;
  }
}
