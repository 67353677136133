@import "../../css/buttons.css";

.section-listings {
  background: #f5f5f5;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
	position: relative;
}

.landing_lead {
  color: #3179ff;
  font-size: 1.25rem;
  font-weight: 400;
  margin: 0 0 2rem;
}

.listing {
  border: 1px solid #b2b2b2;
  background: #fff;
  padding: 1rem 0;
  margin-bottom: 1.4rem;
}

.listing:last-child {
  margin-bottom: 0;
}

.position_name {
  margin: 0.8rem 1rem 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.4;
}

.position_description {
  margin: 0 1rem 1.5rem;
  line-height: 1.4;
  font-size: 1rem;
}

.position_info {
  line-height: 1.4;
  font-size: 1rem;
}

.position-link {
  margin: 0 1rem;
}

.position_link-help {
  border-top: 1px solid #b2b2b2;
  padding: 1rem 1rem 0;
  margin: 1.5rem 0 0;
  color: #8f8f8f;
  font-size: 0.875rem;
  line-height: 1.4;
}

.position_link-help span {
  color: #3179ff;
  margin-right: 8px;
}

@media (min-width: 768px) {
  .section-listings {
    padding-bottom: 5rem;
  }
  .listing {
    margin-bottom: 1.6rem;
  }
  .listing-info {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    padding: 2rem;
  }
  .position_name {
    margin: -5px 2rem 0 0;
    min-width: 22.5%;
  }
}

@media (min-width: 992px) {
  .landing_lead {
    margin: 0 auto 2rem;
    max-width: 69vw;
  }
  .listing {
    max-width: 69vw;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.875rem;
  }
  .listing-info {
    padding: 2rem 2.43rem 0;
  }
}

@media (min-width: 1200px) {
}
