.container-section {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

@media (min-width: 768px) {
  .container-section {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}
