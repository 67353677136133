@import "../../css/buttons.css";

.section-listings {
  background: #f5f5f5;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  position: relative;
}

.referral_lead {
  color: #3179ff;
  font-weight: 400;
  margin: 0 0 2rem;
}
.referral_benefits {
  /* padding: 20px; */
}

.generate-link-button {
  margin-bottom: 20px;
  padding: 20px;
  width: 25%;
  background-color: #3179ff;
  color: white;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
}

.listing {
  border: 1px solid #b2b2b2;
  background: #fff;
  padding: 1rem 0;
  margin-bottom: 1.4rem;
}

.listing:last-child {
  margin-bottom: 0;
}

.position_name {
  margin: 0.8rem 1rem 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.4;
}

.referral_description {
  margin: 0 0 2rem;
  line-height: 1.4;
  font-size: 1rem;
}

.referral_info {
  margin: 0 1rem 1.5rem;
  line-height: 1.4;
  font-size: 1rem;
  white-space: pre-wrap;
  line-height: 1.8;
}
.referral_conditions {
  margin: 0 1rem 1.5rem;
  line-height: 1.4;
  font-size: 0.6rem;
  text-align: left;
}
.position-link {
  margin: 0 1rem;
  text-align: center;
}

.whatsapp {
  background-color: #58be55;
  border: #58be55;
}
.channel-icon {
  margin-bottom: 5px;
  margin-left: 5px;
}

.apply-button {
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
  background-color: #3179ff;
  color: white;
  cursor: pointer;
}

.position_link-help {
  border-top: 1px solid #b2b2b2;
  padding: 1rem 1rem 0;
  margin: 1.5rem 0 0;
  color: #8f8f8f;
  font-size: 0.875rem;
  line-height: 1.4;
}

.position_link-help span {
  color: #3179ff;
  margin-right: 8px;
}

.referral_conditions_container {
  padding-top: 3%;
}

.instructions-image-container {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: auto;
}

@media (min-width: 768px) {
  .referral-text {
    margin-top: 20px;
    margin-bottom: 20px;
    /* max-width: 50%; */
  }
  .section-listings {
    padding-bottom: 5rem;
  }
  .listing {
    margin-bottom: 1.6rem;
  }
  .referral-info {
    padding: 2rem;
  }
  .position_name {
    margin: -5px 2rem 0 0;
    min-width: 22.5%;
  }
  .referral_description,
  .referral_info {
    margin: 0 2rem 1.5rem 0;
  }
  .referral_description,
  .referral_conditions {
    margin: 0 2rem 1.5rem 0;
  }
}

@media (min-width: 992px) {
  .referral_lead {
    margin: 0 auto 2rem;
    max-width: 69vw;
  }
  .listing {
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.875rem;
  }
  .referral-info {
    padding: 2rem 2.43rem 0;
  }
}

@media (min-width: 1200px) {
  .referral_description,
  .referral_info {
    margin: 0 auto 2rem;
    max-width: 69vw;
  }

  .referral_description,
  .referral_conditions {
    margin: 0 auto 2rem;
    max-width: 69vw;
  }
}
