.button-logo-close {
  padding: 1.25rem 1.25rem;
  margin: -1rem -1rem -1rem auto;
  border: none;
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.button-logo-close:focus {
  outline: 0px;
}
.logo-close {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 768px) {
  .logo-close {
    width: 15px;
    height: 15px;
  }
}
