.fade {
  transition: opacity 0.15s linear;
}

.modal {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0px;
  background: rgba(0, 0, 0, 0.6);
}

.modal.show {
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 980px;
    margin: 2rem auto;
  }
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
}

.modal-content h6 {
  font-size: 1.125rem;
  font-weight: 600;
}

.modal-content .divider {
  width: 100%;
  height: 1px;
  background-color: #b2b2b2;
  margin: 2rem 0;
}

.modal-content .alert {
  background: #f5f5f5;
  padding: 2rem;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 2.5rem 1.875rem 1.875rem;
}

.modal-header button {
  background: none;
  padding: 0;
  border: none;
  position: absolute;
  right: 2rem;
}

.modal-header button img {
  width: 21px;
  height: auto;
}

.modal-header button:hover img {
  opacity: 0.5;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 1.875rem;
  font-weight: 700;
  color: #3179ff;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0 1.875rem;
}

.modal-body .modal-title {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 2.5rem 1.875rem 2.5rem;
}

.modal-footer .btn {
  cursor: pointer;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

@media (min-width: 768px) {
  .modal-header button {
    position: relative;
    right: auto;
  }
  .modal-content .alert {
    margin-right: 2rem;
  }
}

@media (min-width: 992px) {
  .modal-header {
    padding: 3.75rem 3.75rem 2rem;
  }
  .modal-body {
    padding: 0 3.75rem;
  }
  .modal-footer {
    padding: 0 3.75rem 3.75rem;
  }
}
