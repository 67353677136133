@import "../../css/buttons.css";

.emi-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1% 10%;
  width: 100%;
  height: 43px;
  background: #22202d;
}

.text-emi-header {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* ligther-blue */

  color: #dae7f5;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.client-logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 64px;
  /* background: #132e82; */
  padding: 1% 10%;
  justify-content: center;
}

.client-logo-container-single-position {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 64px;
  /* background: #132e82; */
  padding: 1% 10%;
  justify-content: center;
}

.client-logo-image {
  width: auto;
  height: 60px;
}

.client-banner {
  width: 100%;
}


.emi-btn-outline-light {
  width: auto;
  align-self: center;
  margin-top: 1%;
}

.link-go-back {
  display: flex;
    flex-direction: row;
    justify-content: start;
    /* position: relative; */
    width: 100%;
}
.client-logo-single-position {
  display: flex;
    flex-direction: row;
    /* width: 100%; */
    align-items: center;
    position: absolute;
    /* z-index: 4; */
    justify-content: center;
}

.dummy-container-single-position-header {
  width: 33%;
}

.link-go-back-text {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #dae7f5;
  margin: 0%;
}

@media (max-width: 700px) {

  .dummy-container-single-position-header {
    width: 0%;
  }
}
