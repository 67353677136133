@import "../../css/buttons.css";

.section-listings {
  background: #f5f5f5;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  position: relative;
}

.landing_lead {
  color: #3179ff;
  font-size: 1.25rem;
  font-weight: 400;
  margin: 0 0 2rem;
}

.listing {
  border: 1px solid #b2b2b2;
  background: #fff;
  padding: 1rem 0;
  margin-bottom: 1.4rem;
}

.listing:last-child {
  margin-bottom: 0;
}

.position_name {
  margin: 0.8rem 1rem 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.4;
}


.position_info {
  line-height: 1.4;
  font-size: 1rem;
}

.position-details {
  display: flex;
}

.position-detail-chip {
  padding: 2%;
  border-radius: 3px;
  color: white;
  background: lightgray;
  font-weight: 600;
  margin-right: 2%;
}

.position-link {
  margin: 0 1rem;
  text-align: center;
}

.whatsapp {
  background-color: #58be55;
  border: #58be55;
}
.channel-icon {
  margin-bottom: 5px;
  margin-left: 5px;
}

.apply-button {
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
  background-color: #3179ff;
  color: white;
  cursor: pointer;
}

.search-listing-input {
  border-radius: 0.5rem;
  width: 50%;
  border-color: transparent;
  background-color: #fff;
  font-size: small;
  font-style: italic;
  padding: 0.5rem;
}

.input-container {
  padding: 1rem 0;
  margin-bottom: 1.4rem;
  max-width: 80vw;
  margin-left: 0;
  margin-right: auto;
}

.search-listing-input:focus {
  border-color: turquoise;
}

.position_link-help {
  border-top: 1px solid #b2b2b2;
  padding: 1rem 1rem 0;
  margin: 1.5rem 0 0;
  color: #8f8f8f;
  font-size: 0.875rem;
  line-height: 1.4;
}

.position_link-help span {
  color: #3179ff;
  margin-right: 8px;
}

.disclaimer_text {
  font-size: 0.875rem;
  font-style: italic;
}

@media (min-width: 768px) {
  .position-text {
    max-width: 50%;
    margin: 1%;
    padding: 2%
  }
  .section-listings {
    padding-bottom: 5rem;
  }
  .listing {
    margin-bottom: 1.6rem;
  }
  .listing-info {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    padding: 2% 2%;
    align-items: center;
  }
  .position_name {
    margin: -5px 2rem 0 0;
    min-width: 22.5%;
  }

  .search-listing-input {
    width: 35%;
  }

  /* .input-container {
    padding: 1rem 0;
    margin-bottom: 1.4rem;
    max-width: 80vw;
    margin-left: 0;
    margin-right: auto;
  } */

  .search-listing-inputr:focus {
    border-color: turquoise;
  }
}

@media (min-width: 992px) {
  .landing_lead {
    margin: 0 auto 2rem;
    max-width: 69vw;
  }
  .listing {
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.875rem;
  }
  .listing-info {
    padding: 2% 2%;
  }
  .disclaimer_text {
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
  }

  .input-container {
    margin-left: auto;
  }
  .search-listing-input {
    width: 30%;
  }
}

@media (min-width: 1200px) {
  .search-listing-input {
    width: 25%;
  }
}
