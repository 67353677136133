.site-footer {
  background: #3179ff;
  color: #fff;
  padding: 2rem 1rem;
}

.more-info-title {
  font-size: 1rem;
  font-weight: 700;
  margin: 0 0 0.7rem 0;
}

.more-info button {
  background-color: inherit;
  border: 0px solid;
  color: #fff;
  text-decoration: underline;
  display: block;
  transition: all ease 0.3s;
}

.more-info button:hover,
.more-info button:active,
.more-info button:focus {
  color: #73a4ff;
}

.site-credit {
  margin-bottom: 3rem;
}

.site-credit span {
  position: relative;
  top: 0.3rem;
  margin-right: 3px;
}

.more-info {
  margin-bottom: 2rem;
}

.site-copy {
  font-size: 0.875rem;
}

@media (min-width: 768px) {
  .site-footer {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    padding: 2rem 1.6rem;
  }
  .site-data {
    text-align: right;
  }
  .more-info {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .site-footer {
    padding: 2.18rem 3rem;
  }
}
