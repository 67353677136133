.sticky {
  transform: translateZ(0px);
  position: absolute;
  z-index: 1;
  top: 32px;
  right: 2rem;
  width: 70px;
  height: 70px;
  background: #262626;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 76px;
  text-align: center;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
}

.sticky:hover,
.sticky:active,
.sticky:focus {
  background: #404040;
}

.sticky a {
  color: #fff;
  display: block;
  text-decoration: none;
}
