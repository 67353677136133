@font-face {
  font-family: "Neris";
  src: url("../fonts/3790A9_0_0.eot");
  src: url("../fonts/3790A9_0_0.eot?#iefix") format("embedded-opentype"),
    url("../fonts/3790A9_0_0.woff2") format("woff2"),
    url("../fonts/3790A9_0_0.woff") format("woff"),
    url("../fonts/3790A9_0_0.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Neris";
  src: url("../fonts/3790A9_6_0.eot");
  src: url("../fonts/3790A9_6_0.eot?#iefix") format("embedded-opentype"),
    url("../fonts/3790A9_6_0.woff2") format("woff2"),
    url("../fonts/3790A9_6_0.woff") format("woff"),
    url("../fonts/3790A9_6_0.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Neris";
  src: url("../fonts/3790A9_7_0.eot");
  src: url("../fonts/3790A9_7_0.eot?#iefix") format("embedded-opentype"),
    url("../fonts/3790A9_7_0.woff2") format("woff2"),
    url("../fonts/3790A9_7_0.woff") format("woff"),
    url("../fonts/3790A9_7_0.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Neris";
  src: url("../fonts/3790A9_4_0.eot");
  src: url("../fonts/3790A9_4_0.eot?#iefix") format("embedded-opentype"),
    url("../fonts/3790A9_4_0.woff2") format("woff2"),
    url("../fonts/3790A9_4_0.woff") format("woff"),
    url("../fonts/3790A9_4_0.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Bogle";
  src: url("../fonts/BOGLEREGULAR.OTF") format("truetype");
  font-display: swap;
  /* font-style: italic; */
}

@font-face {
  font-family: "Bogle ";
  src: url("../fonts/BOGLEITALIC.OTF") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Bogle";
  src: url("../fonts/BOGLEBLACK.OTF") format("truetype");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Bogle";
  src: url("../fonts/BOGLEBLACKITALIC.OTF") format("truetype");
  font-style: italic;
  font-weight: 200;
  color: black;
  font-display: swap;
}

@font-face {
  font-family: "Bogle";
  src: url("../fonts/BOGLEBOLD.OTF") format("truetype");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Bogle";
  src: url("../fonts/BOGLEBOLDITALIC.OTF") format("truetype");
  font-style: italic;
  font-weight: 600;
  font-display: swap;
}
