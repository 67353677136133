@import "../../css/buttons.css";

.referral-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2% 10%;
}

.referral-title-text {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 35px;
  color: #ffffff;
}

.referral-subtitle-text {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  text-align: center;
}

.referral-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2% 10%;
}

.referral-form-title {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 35px;
  color: #22202d;
}
.referral-form-control-input-container {
  padding: 15px 20px;
  width: 315px;
  /* height: 56px; */

  background: #ffffff;
  border: 1px solid #94bce3;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1%;
}
.referral-form-control-input-container-dropdown {
  position: relative;
  height: 56px;
  width: 315px;
  margin-bottom: 1%;
}

.referral-select-selected {
  color: #000000;
  border: none;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-around;
  width: 100%;
}

.referral-select-selected-disabled {
  cursor: unset;
}

.referral-dropdown-filter-element-title {
  margin: 0% 3%;
  width: 90%;
}

.referral-dropdown-filter-element-title-disabled {
  color: #757575;
}
.referral-select-items {
  position: relative;
  z-index: 99;
  overflow: scroll;
  padding: 10px 10px 15px;
  max-height: 399px;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.referral-form-control-input {
  width: 100%;
  margin: 0% 2% 0% 2%;
  border: none;
}

.referral-form-control-input-label {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.referral-form-control-input:focus-visible {
  outline: none;
}

.referral-form-control {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.referral-form-help-button {
  background: none;
  border: none;
}
.referral-form-button-icon-container {
  width: 10%;
}
.referral-form-button-text-container {
  width: 90%;
    text-wrap: nowrap;
    display: flex;
    justify-content: center;
    flex-direction: row;
}
.referral-form-button-icon {
  width: 100%;
  height: auto;
}

.referral-form-help-button:focus-visible {
  outline: none;
  border: none;
}
.referral-form-help-button:focus {
  outline: none;
  border: none;
}

.referral-form-help-text {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #dc742e;
  margin-top: 1%;
}
.referral-form-help-text:focus-visible {
  outline: none;
  border: none;
}
.referral-form-help-text:focus {
  outline: none;
  border: none;
}

.referral-form-warning-text {
  font-family: "Bogle";
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #22202d;
}

.referral-form-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #2f6db3;
  border-radius: 10px;
  border: none;
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  padding: 5%;
}

.referral-instructions-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2% 10%;
  background: #f5f5f5;
}

.referral-instructions-title-text {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 35px;
  color: #132e82;
}

.referral-instructions-subtitle-text {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #132e82;
}

.referral-instructions-step-title {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #132e82;
  margin-bottom: 0.5%;
}

.referral-instructions-step-text {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #4a4a4a;
  margin-bottom: 1%;
}

.referral-instructions-attention {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1%;
  width: 100%;
  background: rgba(148, 188, 227, 0.2);
  border: 1px solid #94bce3;
  border-radius: 10px;
  margin-top: 1%;
}

.referral-instructions-attention-title {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #dc742e;
}

.referral-instructions-attention-text {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.referral-help-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 3% 10%;
  background: #e8e8e8;
}

.referral-help-title {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #132e82;
  margin-bottom: 2%;
}

.referral-help-subtitle {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #132e82;
  margin-bottom: 1%;
}

.referral-help-instructions {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #4a4a4a;
  margin-bottom: 1%;
}

.referral-help-image {
  width: 30%;
  margin-top: 2%;
}

.city-dropdown-filter-element-option {

  padding: 8px 16px;
  border: none;
  cursor: pointer;
  user-select: none;
  font-family: 'Bogle';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.city-dropdown-filter-element-option:hover {
  background-color: #F5F5F5;
}

@media (max-width: 700px) {
  .referral-help-image {
    width: 50%;
    margin-top: 2%;
  }
}
