@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?iuth9i");
  src: url("../fonts/icomoon.eot?iuth9i#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?iuth9i") format("truetype"),
    url("../fonts/icomoon.woff?iuth9i") format("woff"),
    url("../fonts/icomoon.svg?iuth9i#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e900";
}
.icon-copy:before {
  content: "\e901";
}
.icon-doble-check:before {
  content: "\e902";
}
