.logo-footer-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 153px;
  padding: 0% 10%;
}

.footer-client-logo-container-first {
  width: 20%;
}

.footer-client-logo-container {
  margin: auto;
  width: 60%;
  display: flex;
  justify-content: center;
}

.footer-client-logo {
}

.emi-footer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 59px;
  background: #22202d;
  font-family: "Bogle";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}

.emi-logo-footer {
  width: 45px;
  height: auto;
}

.emi-footer-made {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-client-logo-social-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
}

.footer-client-logo-social-title {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #22202d;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer-client-logo-social-logos {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer-client-logo-social-logo {
  width: 20px;
  margin: 0% 3%;
}

.footer-privacy-policy-link {
  color: white;
}

@media (max-width: 700px) {
  .logo-footer-container {
    padding: 5% 10%;
    flex-direction: column;
    height: 100%;
  }
  .footer-client-logo-social-container {
    width: 60%;
    margin-top: 3%;
  }

  .emi-footer-container {
    height: 100%;
    padding: 0% 3%;
    flex-direction: column;
  }

  .emi-footer-made {
    margin: 2%;
  }
}

@media (min-width: 992px) {
}
