.emi-btn {
  display: inline-block;
  font-weight: 700;
  color: #262626;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.8rem 2rem !important;
  border-radius: 0px !important;
  font-size: 1.125rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.emi-btn:focus {
  outline: none;
}

.emi-btn-primary {
  color: #fff;
  background-color: #3179ff;
  border-color: #3179ff;
}

.emi-btn-primary:hover,
.emi-btn-primary:active,
.emi-btn-primary:focus {
  background-color: #73a4ff;
  border-color: #73a4ff;
}

.emi-btn-outline {
  color: #3179ff;
  border-color: #3179ff;
}

.emi-btn-outline a {
  color: #3179ff;
  display: block;
  text-decoration: none;
}

.emi-btn-outline:hover,
.emi-btn-outline:active,
.emi-btn-outline:focus {
  color: #73A4FF;
  border-color: #73A4FF;
}

.emi-btn-outline-light {
  color: #fff;
  border-color: #fff;
}

.emi-btn-outline-light a {
  color: #fff;
  display: block;
  text-decoration: none;
}
.emi-btn-outline-light:hover,
.emi-btn-outline-light:active,
.emi-btn-outline-light:focus {
  /* color: #73a4ff;
  border-color: #73a4ff; */
}

.emi-btn-outline-dark {
  color: #000;
  border-color: #000;
}

.emi-btn-outline-dark a {
  color: #000;
  display: block;
  text-decoration: none;
}
.emi-btn-outline-dark:hover,
.emi-btn-outline-dark:active,
.emi-btn-outline-dark:focus {
  color: #73a4ff;
  border-color: #73a4ff;
}

.emi-btn-caps {
  text-transform: uppercase;
}

.emi-btn-clipboard {
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  color: #3179ff;
  word-break: break-word;
}

.emi-btn-clipboard:before {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e901";
}
