@import "../../css/buttons.css";

.intro-logo {
  background: #fff;
  /* padding: 0rem 1.25rem; */
  display: flex;
}

.company_logo {
  margin: auto;
  width: 75%;
  height: auto;
}

.company_logo_alsea {
  margin: auto;
  width: 75%;
  height: auto;
}

.intro-text {
  background: #3179ff;
  padding: 3%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.emi_logo_container{
  margin: 2%
}
.emi_logo {
  height: 30px;
  width: auto;
}

.landing_title {
  color: #fff;
  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: 700;
  /* margin: 0 0 2rem 0; */
  margin-bottom: 0%;
  align-self: center;
}

.emi-btn-outline-light {
  width: auto;
  align-self: center;
  margin-top: 1%;
}

@media (min-width: 768px) {
  .landing_title {
    max-width: 75vw;
    font-size: 2.25rem;
  }

  .company_logo {
    margin: auto;
  }
}

@media (min-width: 992px) {
  .intro {
    display: flex;
  }
  .landing_title {
    max-width: none;
    font-size: 1.83rem;
    align-self: flex-start;
  }
  .intro-logo {
    order: 2;
    width: 40%;
  }

  .intro-text {
    width: 60%;
  }

  .company_logo {
    /* position: absolute; */
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
  }
}
