@import "./css/normalize.css";
@import "./css/grid.css";
@import "./css/fonts.css";
@import "./css/icons.css";
@import "./components/Header/Header.css";
@import "./components/Section/Section.css";
@import "./components/Section_Help/SectionHelp.css";
@import "./components/Footer/Footer.css";
@import "./components/Section/sticky.css";
@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:regular,bold,italic&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,700");

body {
  font-family: "Neris", sans-serif;
  color: #262626;
  font-size: 16px;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: max-content;
}
