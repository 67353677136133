@import "../../css/buttons.css";

.main-filters-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.main-title {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 35px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  margin: 2%;
}

.filters-description {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  padding: 0% 5%;
}

.main-filters-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 2% 6%;
}

/* Removed main position filter */
.main-position-filter {
  background: #ffffff;
  display: flex;
  flex-direction: row;
  height: 56px;
  width: 25%;
  align-items: center;
  padding: 1%;
  margin: 0% 1% 0% 0%;
  border-radius: 5px;
}

.main-position-filter-element {
  background: none;
  border: none;
  margin: 3%;
}

.main-position-filter-element:focus-visible {
  outline: none;
}

.main-position-filter-element::placeholder {
  font-family: 'Bogle';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #22202D;
  opacity: 0.6;
}

.dropdown-filter {
  background: #ffffff;
  height: 56px;
  width: 200px;
  border-radius: 5px;
  margin: 0% 1% 0% 0%;
}

.dropdown-sub-filter {
  background: #ffffff;
  height: 56px;
  width: 288px;
  border-radius: 5px;
  margin: 0% 1% 0% 0%;
  border: 1px solid #A4A6A8;
}

.state-dropdown-filter {
  width: 200px;
}

.city-dropdown-filter {
  width: 300px;
}

.city-dropdown-filter-disabled {
  background: #DCDCDC;
}


.city-dropdown-filter-element {
  width: 100%;
  background: transparent;
  border: none;
  color: transparent;
  position: absolute;
  display: none;

}

.city-dropdown-filter-element:focus-visible {
  outline: none;
}

.select-selected {
  color: #000000;
  padding: 8px 16px;
  border: none;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.select-selected-disabled {
  cursor: unset;
}

.city-dropdown-filter-element-option {

  padding: 8px 16px;
  border: none;
  cursor: pointer;
  user-select: none;
  font-family: 'Bogle';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.city-dropdown-filter-element-option:hover {
  background-color: #F5F5F5;
}

.city-dropdown-filter-element-title {
  margin: 0% 3%;
  font-family: 'Bogle';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #22202D;
  width: 90%;
  text-align: center;
  opacity: 0.6;
}

.city-dropdown-filter-element-title-disabled {
  color: #8D8D8D;
}


.select-items {
  position: relative;
  z-index: 99;
  overflow: scroll;
  padding: 10px 10px 15px;
  max-height: 399px;
  margin: 10px 0px;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}


.select-hide {
  display: none;
}

/* .select-hide:focus {
  display: block;
} */


.main-position-filter-button {
  width: 20%;
  /* width: 25%; */
}

.main-position-filter-button-element {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  height: 56px;
  border: none;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.main-position-filter-button-element:focus {
  outline: none;
}

.main-position-filter-button-image {
  width: 15%;
  margin: 2%;
}

.positions-section-title {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 35px;
  text-align: center;
}

.positions-section-subtitle {
  font-family: 'Bogle';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  align-items: center;
  text-align: center;
  color: #22202D;
  opacity: 0.6;
  margin-top: 2%
}

.positions-section-filters-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 2% auto;
  width: 70%;
}

.positions-section-dropdown-filter {
  background: #ffffff;
  border-radius: 3%;
  display: flex;
  flex-direction: row;
  height: 56px;
  width: 30%;
  border: 1px solid #A4A6A8;

}

.positions-section-dropdown-filter-element {
  background: none;
  border: none;
  width: 100%;
  margin: 3%;
  font-family: "Bogle";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #22202d;
}

.positions-section-dropdown-filter-element:focus-visible {
  outline: none;
}

.positions-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1%;
}

.section-listings {
  background: #f5f5f5;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  position: relative;
}

.position-card {
  display: flex;
  flex-direction: column;
  margin: 1%;
  padding-left: 2%;
  padding-right: 2%;
  width: 345px;
  /* height: 320px; */
  background: #ffffff;
  border-radius: 10px;
  border-color: #ffffff;
  justify-content: space-evenly;
}

.position-card-name-container {
  display: flex;
  /* height: 20%; */
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2%;
}

.position-card-name {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
  margin-top: 5%;
}

.position-card-location-brand-container {
  /* height: 25%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2%;
}

.position-card-location {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #dc742e;
}

.position-card-brands {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #121d2b;
  opacity: 0.6;
}

.position-card-text-container {
  /* height: 31%; */
  margin-bottom: 2%;
}

.position-card-text {
  line-height: 1.4;
  font-size: 1rem;
  font-family: "Bogle";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #000000;
}

.position-card-links-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* height: 24%; */
  margin-bottom: 2%;
}

.position-card-links {}

.position-card-details {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  border-bottom: 2px solid #2f6db3;

  /* main-blue */

  color: #2f6db3;
}

.position-card-details:hover {
  text-decoration: none;
}

.position-card-apply {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 152px;
  height: 48px;
  border-radius: 10px;
  justify-content: space-evenly;
}

.position-card-apply-text {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  /* color: #2f6db3; */
}

.position-card-apply-channels-container {
  margin: 5px;
  display: flex;
  flex-direction: row;
}

.position-card-apply-channel {
  margin: 5px;
}

.position-card-apply-channel-img {
  height: 17px;
}

.positions-load-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.positions-load-button {
  height: 52px;
  padding: 1%;

  background: #fff0e7;
  /* orange */

  border: 2px solid #dc742e;
  border-radius: 10px;

  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: row;
  justify-content: center;

  /* wm/orange */

  color: #dc742e;
}

.positions-load-button:disabled {
  width: 196px;
  height: 52px;

  background: #f4faff;
  /* orange */

  border: 2px solid #dee7e7;
  border-radius: 10px;

  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;

  /* wm/orange */

  color: #dee7e7;
}

.positions-load-button:focus {
  outline: none;
}

.position-link {
  margin: 0 1rem;
  text-align: center;
}

.modal-section {
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  left: 0px;
  top: 0px;
}

.modal-apply-close {
  z-index: 1;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 90%;
}

.modal-apply-close-button {
  border: none;
  background: none;
}

.modal-apply-position {
  width: 533px;
  height: 494px;
  background: #f1f8ff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2%;
  position: relative;
}

.modal-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-apply-text-message {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 139.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  color: #000000;
}

.modal-apply-position-name {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  color: #000000;
}

.modal-apply-chat-message {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #000000;
}

.modal-apply-select-channel-text {
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.modal-apply-select-channel-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal-apply-button {
  border-radius: 10px;
  width: 226px;
  height: 54px;
  border: none;
  font-family: "Bogle";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;

  /* wm/white */

  color: #ffffff;
}

.modal-apply-link-details {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (max-width: 700px) {
  .main-filters-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .main-position-filter {
    width: 250px;
    margin: 5%;
    padding: 3%;
  }

  .city-dropdown-filter {
    width: 250px;
    margin: 5%;
    padding: 3%;
  }

  .state-dropdown-filter {
    width: 250px;
    margin: 5%;
    padding: 3%;
  }

  .dropdown-sub-filter {
    margin: 1%;
    width: 100%;
  }

  .main-position-filter-button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .mobile-filter-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 3%;
  }

  .mobile-filter-button {
    width: 50%;
    background: #f5f5f5;
    border: 2px solid #a4a6a8;
    border-radius: 10px;
  }

  .mobile-filter-button-emoji {
    padding-right: 3%;
  }

  .mobile-filter-button-text {
    font-family: "Bogle";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    color: #dc742e;
    margin: 5% 2%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .mobile-filter-button-modal {
    width: 100%;
    background: #f5f5f5;
    background: #dc742e;
    border-radius: 10px;
    border: none;
  }

  .mobile-filter-button-modal-text {
    font-family: "Bogle";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    margin: 6%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .mobile-filters-section {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    bottom: 0;
  }

  .mobile-filters-container {
    width: 100%;
    height: 500px;
    background: #ffffff;
    padding: 2%;
    bottom: 0;
    position: fixed;
    padding: 2%;
  }

  .mobile-filters-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: "Bogle";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #dc742e;
    width: 100%;
  }

  .positions-section-filters-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 2% auto;
  }

  .positions-section-dropdown-filter {
    margin: 3% 0%;
    background: #ffffff;
    border: 1px solid #a4a6a8;
    border-radius: 5px;
    width: 100%;
  }

  .position-card {
    padding: 0% 3%;
  }

  .modal-apply-select-channel-buttons {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modal-apply-button {
    margin: 1%;
  }

  .modal-apply-position {
    height: 550px;
    padding: 3% 5%;
    bottom: 0;
    position: fixed;
    width: 100%;
  }
}