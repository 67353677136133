.section-help {
  background: #404040;
  color: #fff;
  padding: 2.5rem 1rem;
}

.section-help-title {
  font-size: 1.5rem;
  margin: 0 0 1.5rem 0;
  font-weight: 600;
}

.section-help p {
  border-top: 1px solid #a0a0a0;
  padding: 1.25rem 3rem 1.25rem 0;
  margin: 0;
  position: relative;
}

.section-help p:last-child {
  padding-bottom: 0;
}

.section-help p:after {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e902";
  position: absolute;
  right: 0;
  top: 1.25rem;
  color: #73a4ff;
  font-size: 1.2rem;
}

.section-help p strong {
  font-weight: 600;
}

.section-help p span {
  font-weight: 400;
}
.section-help button {
  margin: 0px;
  padding: 0px;
  border: none;
  background: transparent;
}
.section-help button:focus {
  outline: 0px;
}
.section-help p button {
  color: #73a4ff;
  text-decoration: underline;
}

.claim {
  display: none;
  font-size: 1.125rem;
  margin-right: 3.5rem;
  margin-top: 3.5rem;
}

.claim span {
  display: block;
}

@media (min-width: 768px) {
  .section-help {
    padding: 3.125rem 1.6rem;
  }
  .section-help-title {
    font-size: 1.625rem;
  }
  .section-help p {
    font-size: 1.125rem;
  }
  .section-help-title,
  .section-help p {
    max-width: 580px;
  }
}

@media (min-width: 992px) {
  .section-help {
    padding: 3.125rem;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
  }
  .claim {
    display: block;
  }
}
